<template>
  <div class="zt-page-content">
    <ItemApply @shareState="shareState"></ItemApply>
    <div class="header flex">
      <div
        v-if="shareItem && shareItem.stat == 2 && comp_type && comp_type == 3"
        class="tab_menu flex"
      >
        <div
          v-for="(item, index) in tabList"
          :key="index"
          @click="tab(index)"
          class="menu_item flex"
          :class="{ cur: tabIdx == index }"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
      <!-- <div class="setPrice flex-1 flex flex-align-center flex-justify-between">
                <div class="flex flex-align-center">
                    <div>零售价：设置为集市价</div>
                    <div style="width:88px;">
                        <el-input v-model.number="set_price_lv.lv1" type="number" size="small"></el-input>
                    </div>
                    <div>倍</div>
                </div>
                <div class="flex flex-align-center">
                    <div>下级批发价：设置为集市价</div>
                    <div style="width:88px;">
                        <el-input v-model.number="set_price_lv.lv2" type="number" size="small"></el-input>
                    </div>
                    <div>倍</div>
                </div>
                <div class="flex flex-align-center">
                    <div>批发价：设置为集市价</div>
                    <div style="width:88px;">
                        <el-input v-model.number="set_price_lv.lv3" type="number" size="small"></el-input>
                    </div>
                    <div>倍</div>
                </div>
                <div>
                    <el-button style="margin:0;" @click="changeSwitch(item,idx)" size="mini" type="primary">批量设置</el-button>
                </div>
            </div> -->
    </div>
    <div
      v-if="shareItem && shareItem.stat == 2"
      class="flex"
      style="margin: 20px"
    >
      <div class="flex-1" style="line-height: 32px">
        已添加商品数：
        <span style="color: #2bbaae">
          <span>{{ total }}/</span>
          <span
            v-if="
              (shareItem && shareItem.n_product_limit == 99999) ||
              (comp_type == 3 && tabIdx == 0)
            "
            >--</span
          >
          <span v-else>{{ shareItem && shareItem.n_product_limit }}</span>
        </span>
      </div>
      <el-button
        v-if="tabIdx == 1 || comp_type == 1"
        @click="addGoods()"
        size="small"
        type="primary"
        >添加商品</el-button
      >
    </div>
    <div
      v-if="shareItem && shareItem.stat == 2"
      class="zt-block"
      :style="
        comp_type && comp_type == 3
          ? 'height:calc(100% - 140px)'
          : 'height:calc(100% - 80px)'
      "
    >
      <div class="thead">
        <el-row>
          <el-col :span="5">商品图片</el-col>
          <el-col :span="5">商品名称</el-col>
          <el-col :span="4">是否上架</el-col>
          <el-col :span="4">类目</el-col>
          <el-col :span="6">操作</el-col>
        </el-row>
      </div>
      <div
        style="height: calc(100% - 115px); overflow-y: auto"
        ref="load"
        class="table flex-1"
      >
        <div
          v-if="shareItem && shareItem.stat == 2 && list && list.length > 0"
          class="tbody"
        >
          <div v-for="(item, idx) in list" :key="idx" class="order-block">
            <el-row class="order-body flex">
              <el-col :span="5" class="flex flex-center">
                <div @click="gotoDetail()" style="width: 80px; height: 80px">
                  <div
                    v-if="item.photo_render && item.photo_render.length > 0"
                    class="bg_img"
                    :style="{
                      backgroundImage:
                        'url(' +
                        imgUrl +
                        item.photo_render[0] +
                        '!width_250px' +
                        ')',
                    }"
                  ></div>
                </div>
              </el-col>
              <el-col :span="5" class="flex flex-center">
                <a @click="gotoDetail(item)" class="ellipsis">{{
                  item.name
                }}</a>
              </el-col>
              <el-col :span="4" class="flex flex-center">
                <span v-if="item && item.fx_stat == 1" style="color: #2bbaae"
                  >已上架</span
                >
                <span v-if="item && item.fx_stat == 2" style="color: #f66f6a"
                  >已下架</span
                >
                <el-popover
                  v-if="item && item.fx_stat == 3"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  content="本商品工厂已下架"
                >
                  <span class="shixiao" slot="reference">已失效</span>
                </el-popover>
                <el-popover
                  v-if="item && item.fx_stat == 4"
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  content="工厂已调整本商品的SKU信息，请重新编辑后上架"
                >
                  <span class="shixiao" slot="reference">需修改</span>
                </el-popover>
                <span v-if="item && item.fx_stat == 5">已删除</span>
              </el-col>
              <el-col :span="4" class="flex flex-center">
                <div v-if="item.cat" class="ellipsis">{{ item.cat.name }}</div>
              </el-col>
              <el-col :span="6" class="flex flex-center">
                <el-button
                  v-if="item.fx_stat == 2 && item.price_type == 5"
                  @click="setSpu(item)"
                  size="mini"
                  type="primary"
                  >修改基本信息</el-button
                >
                <el-button
                  v-if="item.fx_stat == 2 && item.price_type == 5"
                  @click="setSku(item)"
                  size="mini"
                  type="primary"
                  >修改规格属性</el-button
                >
                <el-button
                  v-if="item.fx_stat == 1"
                  @click="changeSwitch(item, idx)"
                  size="mini"
                  type="primary"
                  >下架</el-button
                >
                <el-button
                  v-if="item.fx_stat == 2"
                  @click="changeSwitch(item, idx)"
                  size="mini"
                  type="primary"
                  >上架</el-button
                >
                <el-button @click="del(item)" size="mini" type="danger"
                  >移除</el-button
                >
              </el-col>
            </el-row>
            <div class="order-foot flex">
              <div
                v-show="listIndex == idx"
                v-if="item.sku && item.sku.length > 0"
                class="skuList"
              >
                <div class="skuhead flex">
                  <div class="flex-1">材质</div>
                  <div class="flex-1">颜色</div>
                  <div
                    class="flex-1"
                    v-for="(attr, idx2) in item.sku[0].attrs"
                    :key="idx2"
                  >
                    {{ attr.name }}
                  </div>
                  <!-- <div v-if="item.sku[0].attrs && item.sku[0].attrs.length>0" class="flex"></div> -->
                  <div class="input flex-1">零售价</div>
                  <div class="input flex-1">下级批发价</div>
                  <div class="input flex-1">批发价</div>
                  <div v-if="item.source_type == 2" class="input flex-1">
                    集市价
                  </div>
                </div>
                <div class="skubody">
                  <div
                    class="skubody-item flex"
                    v-for="(sku, idx1) in item.sku"
                    :key="idx1"
                  >
                    <div class="flex-1">{{ sku.material_name }}</div>
                    <div class="flex-1">{{ sku.color_name }}</div>
                    <div
                      class="flex-1"
                      v-for="(attr, idx2) in sku.attrs"
                      :key="idx2"
                    >
                      {{ attr.value }}
                    </div>
                    <!-- <div v-if="sku.attrs && sku.attrs.length>0" class="flex"></div> -->
                    <div class="input flex-1 flex flex-center">
                      <div>￥</div>
                      <div class="flex-1">
                        <el-input
                          v-model.number="sku.price_lv_4"
                          type="number"
                          size="small"
                        ></el-input>
                      </div>
                      <!-- <div v-if="sku.price_lv_4" class="font14-grey1"> ({{(sku.price_lv_4 / sku.price_lv_3) | discountFilt}}折)</div> -->
                    </div>
                    <div class="input flex-1 flex flex-center">
                      <div>￥</div>
                      <div class="flex-1">
                        <el-input
                          v-model.number="sku.price_lv_3"
                          type="number"
                          size="small"
                        ></el-input>
                      </div>
                      <div
                        v-if="sku.price_lv_3 && sku.price_lv_4"
                        class="font14-grey1"
                      >
                        ({{
                          (sku.price_lv_3 / sku.price_lv_4) | discountFilt
                        }}折)
                      </div>
                    </div>
                    <div class="input flex-1 flex flex-center">
                      <div>￥</div>
                      <div class="flex-1">
                        <el-input
                          v-model.number="sku.price_lv_2"
                          type="number"
                          size="small"
                        ></el-input>
                      </div>
                      <div
                        v-if="sku.price_lv_2 && sku.price_lv_4"
                        class="font14-grey1"
                      >
                        ({{
                          (sku.price_lv_2 / sku.price_lv_4) | discountFilt
                        }}折)
                      </div>
                    </div>
                    <div
                      v-if="item.source_type == 2"
                      class="input flex-1 flex flex-center"
                    >
                      <div>￥</div>
                      <div class="flex-1">
                        <el-input
                          v-model.number="sku.price_lv_1"
                          type="number"
                          size="small"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sku-button flex flex-justify-around">
                  <el-button
                    @click="packdown()"
                    style="width: 76px"
                    size="small"
                    type="primary"
                    plain
                    >取消</el-button
                  >
                  <!-- <el-button @click="packdown(item)" style="width:76px;" size="small" type="primary" plain>清空</el-button> -->
                  <el-button
                    @click="edit(item)"
                    style="width: 76px"
                    size="small"
                    type="primary"
                    >保存</el-button
                  >
                </div>
              </div>
              <div class="skubutton flex flex-center">
                <a @click="packdown()" v-if="listIndex == idx">收起</a>
                <a @click="packup(item, idx)" v-else>展开</a>
                <i
                  @click="packdown()"
                  v-if="listIndex == idx"
                  class="active iconfont icon-youjiantou"
                ></i>
                <i
                  @click="packup(item, idx)"
                  v-else
                  class="iconfont icon-youjiantou"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-v flex-center font14-grey1"
          style="height: 100%"
        >
          暂无数据
        </div>
      </div>
      <div v-if="pageShow" style="padding: 10px 0; text-align: center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page.sync="queryOpt.page"
          :page-size="queryOpt.pagesize"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <div
      v-if="shareItem && shareItem.stat == 2 && wx_code"
      class="wxcode flex flex-align-center"
      :class="wx_right ? 'move-right' : ''"
    >
      <div @click="wxRight" class="title">
        扫码进入小程序
        <i class="iconfont icon-fanhuitubiao1"></i>
      </div>
      <div class="wx_code flex-1 flex flex-center">
        <img
          style="position:relative;top:-80px
                        transform:scale(0.4);
                        -ms-transform:scale(0.4);
                        -moz-transform:scale(0.4);
                        -webkit-transform:scale(0.4);
                        -o-transform:scale(0.4);"
          :src="wx_code"
        />
      </div>
      <!-- <div class="title" style="position:relative;top:-10px">可查看预览商品</div> -->
    </div>

    <el-dialog
      width="650px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <ItemGoods @hide="popVisible = false" @success="refreshItems"></ItemGoods>
    </el-dialog>
  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
import { debounce } from "lodash";
import {
  listFxProduct,
  setFxProductPrice,
  statFxProduct,
  getStoreWxqr,
  delFxProduct,
} from "@/service/sharer";
import ItemApply from "../ItemApply.vue";
import ItemGoods from "./ItemGoods.vue";
export default {
  components: {
    ItemApply,
    ItemGoods,
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popTitle: null,
      popVisible: false,
      comp_type: null,
      tabList: [
        { id: 1, name: "集市商品" },
        { id: 2, name: "自有商品" },
      ],
      tabIdx: 0,
      queryOpt: {
        price_type: [],
        page: 1,
        pagesize: 10,
      },
      total: 10,
      list: [],
      listIndex: null,
      wx_code: null,
      shareItem: null,
      pageShow: true,
      wx_right: false,
      set_price_lv: {
        lv1: "",
        lv2: "",
        lv3: "",
      },
    };
  },
  // watch: {
  //     queryOpt: {
  //         handler() {
  //             this.debounceRefreshItems();
  //         },
  //         deep: true
  //     },
  // },
  created() {
    this.comp_type = this.$store.state.loginUser.comp.comp_type;
    if (this.comp_type == 1) {
      this.queryOpt.price_type = [0, 1, 2];
    } else if (this.comp_type == 3) {
      console.log(this.$route.query.tabIdx);
      if (this.$route.query.tabIdx == 0) {
        this.tabIdx = this.$route.query.tabIdx;
        this.queryOpt.price_type = [0, 1, 2];
      } else if (this.$route.query.tabIdx == 1) {
        this.tabIdx = this.$route.query.tabIdx;
        this.queryOpt.price_type = [5];
      } else {
        this.queryOpt.price_type = [0, 1, 2];
      }
    }
    // this.refreshItems();
  },
  mounted() {},
  methods: {
    gotoDetail(item) {
      console.log(item);
      if (this.comp_type == 1) {
        window.open("/factory/zysp/detail/" + item.factory_product_no);
      } else {
        window.open(
          "/sale/mall/home/factory_detail/" + item.factory_product_no
        );
        // if (item.price_type==1) {
        //     window.open('/sale/mall/home/design_detail/'+item.factory_product_no);
        // } else if (item.price_type==0) {
        //     window.open('/sale/mall/home/factory_detail/'+item.factory_product_no);
        // }
      }
    },
    wxRight() {
      this.wx_right = !this.wx_right;
    },
    shareState(item) {
      this.shareItem = item;
      if (this.shareItem.stat == 2) {
        this.refreshItems();
      }
    },
    addGoods() {
      if (this.comp_type == 1) {
        this.popTitle = "添加自有商品";
        this.popVisible = true;
      } else if (this.comp_type == 3) {
        this.$router.push("/agent/agentGoods/addSelfGoods");
      }
    },
    // 选项卡
    tab(index) {
      this.tabIdx = index;
      this.$router.push({
        query: { tabIdx: this.tabIdx },
      });
      this.queryOpt.page = 1;
      if (index == 0) {
        this.queryOpt.price_type = [0, 1, 2];
      } else {
        this.queryOpt.price_type = [5];
      }
      console.log(index);
      this.pageShow = false;
      this.$nextTick(() => {
        this.pageShow = true;
      });
      this.refreshItems();
    },
    currentChange(e) {
      this.refreshItems();
    },
    refreshItems() {
      this.list = [];
      const _loading = this.$loading({
        lock: true,
        text: "拼命加载中...",
        target: this.$refs.load,
      });
      // if (this.total)
      listFxProduct(this.queryOpt)
        .then((rst) => {
          this.total = rst.total;
          this.list = rst.list;
          this.get_wx_code();
          console.log(this.list);
          _loading.close();
        })
        .catch((err) => {
          _loading.close();
          console.log(err);
          this.$message.error(err.message);
        });
    },
    get_wx_code() {
      let obj = {
        fx_product_id: 0,
        page_uri: "pages/index/index",
        img_width: "100px",
      };
      getStoreWxqr(obj)
        .then((rst) => {
          this.wx_code = "data:image/png;base64," + rst.img;
        })
        .catch((err) => {
          console.log(err);
          // this.$message.error(err.message);
        });
    },
    packup(item, idx) {
      // console.log(idx);
      this.listIndex = idx;
    },
    packdown(item) {
      // console.log(item);
      this.refreshItems();
      this.listIndex = null;
    },
    changeSwitch(item, index) {
      // 上下架
      let text = null,
        stat = null;
      if (item.fx_stat == 1) {
        text = "下架";
        stat = 2;
      } else if (item.fx_stat == 2) {
        text = "上架";
        stat = 1;
      }
      this.$confirm("确认是否" + text + "?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        statFxProduct({ fx_product_id: item.id, stat: stat })
          .then(() => {
            // this.loadItems();
            this.list[index].fx_stat = stat;
            this.$message.success(text + "成功！");
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.message);
          });
      });
      console.log(item);
    },
    edit(item) {
      if (item && item.sku && item.sku.length > 0) {
        let obj = {
          fx_product_id: item.id,
          price_list: [],
        };
        let falg = true;
        console.log(111, item.sku);
        item.sku.forEach((e) => {
          if (item.source_type == 2) {
            if (
              [e.price_lv_1, e.price_lv_2, e.price_lv_3, e.price_lv_4].indexOf(
                0
              ) > 0
            ) {
              console.log(
                333,
                [
                  e.price_lv_1,
                  e.price_lv_2,
                  e.price_lv_3,
                  e.price_lv_4,
                ].indexOf(0)
              );
              falg = false;
              return;
            }
          } else {
            if (
              [e.price_lv_2, e.price_lv_3, e.price_lv_4].some(
                (item) => item === 0
              )
            ) {
              console.log(333, "有一个为0");
              falg = false;
              return;
            }
            if (
              [e.price_lv_2, e.price_lv_3, e.price_lv_4].some(
                (item) => item === ""
              )
            ) {
              falg = false;
              return;
            }
          }
          obj.price_list.push({
            fx_sku_id: e.id,
            price_lv_1: parseFloat(e.price_lv_1),
            price_lv_2: parseFloat(e.price_lv_2),
            price_lv_3: parseFloat(e.price_lv_3),
            price_lv_4: parseFloat(e.price_lv_4),
          });
        });
        if (falg) {
          setFxProductPrice(obj).then(()=>{
              this.refreshItems();
              this.packdown();
              this.$message.success('保存成功!');
          }).catch(err => {
              console.log(err);
              this.$message.error(err.message);
          });
        } else {
          this.$message.error("价格都不能为零或者为空");
        }
      }
    },
    setSpu(item) {
      this.$router.push({
        path: "/agent/agentGoods/addSelfGoods",
        query: { id: item.id },
      });
    },
    setSku(item) {
      this.$router.push("/agent/agentGoods/ItemSku/" + item.id);
    },
    // 删除
    del(item) {
      this.$confirm("确认是否从商品库移除?", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delFxProduct({ fx_product_id: item.id })
          .then(() => {
            console.log("page", this.total % this.queryOpt.pagesize);
            if (this.total % this.queryOpt.pagesize == 1) {
              this.queryOpt.page = 1;
            }
            this.refreshItems();
            this.$message.success("移除成功!");
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.message);
          });
      });
    },
  },
};
</script>
<style src="../style.css"></style>
<style scoped>
.zt-page-content >>> input[type="number"] {
  padding: 0 0 0 15px;
}
</style>
<style scoped lang="less">
.zt-block {
  background: transparent;
  box-shadow: none;
  margin: 0 20px;
}
.header {
  width: 100%;
  margin: 20px;
  .tab_menu {
    height: 40px;
    .menu_item {
      padding: 0 30px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #5074ee;
      border-radius: 20px;
      margin-right: 30px;
      cursor: pointer;
      font-size: 16px;
      color: #5074ee;
    }
    .menu_item.cur {
      color: #fff;
      background: #5074ee;
      border-radius: 21px;
    }
  }
  .setPrice {
    padding-right: 20px;
    font-size: 16px;
    color: #656565;
    > div {
      margin-right: 20px;
    }
    .el-input {
      padding: 0 8px;
    }
  }
}

.wxcode.move-right {
  transform: translateX(130px);
  -ms-transform: translateX(130px); /* IE 9 */
  -moz-transform: translateX(130px); /* Firefox */
  -webkit-transform: translateX(130px); /* Safari 和 Chrome */
  -o-transform: translateX(130px); /* Opera */
  .title {
    i {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg); /* IE 9 */
      -moz-transform: rotate(180deg); /* Firefox */
      -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
      -o-transform: rotate(180deg); /* Opera */
    }
  }
}
.wxcode {
  width: 160px;
  height: 160px;
  background: #ffffff;
  border: 4px solid #016fff;
  border-radius: 6px;
  position: fixed;
  right: 2px;
  bottom: 140px;
  overflow: hidden;
  transition: all 0.2s linear;
  .title {
    width: 24px;
    text-align: center;
    font-size: 14px;
    color: #5074ee;
    line-height: 17px;
    padding: 6px 0 4px;
    cursor: pointer;
    i {
      display: inline-block;
      transform: rotate(0);
    }
  }
  .wx_code {
    // width: 100%;
    width: 125px;
    height: 120px;
  }
}
.thead {
  padding: 0 56px 0 40px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  .el-col {
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
}
.table {
  .tbody {
    .order-block {
      // border-left: 1px solid #e6e6e6;
      // border-right: 1px solid #e6e6e6;
      // border-bottom: 1px solid #e6e6e6;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
      margin-bottom: 16px;
      .order-body {
        height: 120px;
        margin: 0 40px;
        border-bottom: 1px solid #cccccc;
        .shixiao {
          padding: 0 8px;
          background: #fffbe6;
          border: 1px solid #ffe58f;
          font-size: 12px;
          color: #faad14;
          cursor: pointer;
        }
        .el-row:nth-child(2n) {
          background: #fafafa;
        }
        .el-col {
          text-align: center;
        }
      }
      .order-foot {
        width: 100%;
        position: relative;
        text-align: center;
        .skuList {
          width: 100%;
          background: #f5f5f5;
          padding: 0 40px;
          transition: all 0.2s linear;
        }
        .skuhead {
          height: 60px;
          line-height: 60px;
          font-size: 16px;
          font-weight: 600;
          color: #4d4d4d;
          border-bottom: 1px solid #cccccc;
          .input {
            width: 135px;
            padding: 0 4px;
          }
        }
        .skubody {
          border-bottom: 1px solid #cccccc;
          .skubody-item {
            height: 60px;
            line-height: 60px;
            font-size: 14px;
            color: #4d4d4d;
            .input {
              width: 135px;
              padding: 0 4px;
            }
            .input .el-input {
              max-width: 90%;
              // padding-left: 2px;
            }
          }
        }
        .sku-button {
          width: 300px;
          margin: 0 auto;
          padding: 20px 0;
        }
        .skubutton {
          width: 100%;
          cursor: pointer;
          a {
            font-size: 14px;
            color: #0091ff;
            padding: 10px 0;
          }
          i {
            display: inline-block;
            transform: rotate(90deg);
            -ms-transform: rotate(90deg); /* IE 9 */
            -moz-transform: rotate(90deg); /* Firefox */
            -webkit-transform: rotate(90deg); /* Safari 和 Chrome */
            -o-transform: rotate(90deg); /* Opera */
            font-size: 12px;
            color: #0091ff;
            padding: 5px;
            &.active {
              display: inline-block;
              transform: rotate(270deg);
              -ms-transform: rotate(270deg); /* IE 9 */
              -moz-transform: rotate(270deg); /* Firefox */
              -webkit-transform: rotate(270deg); /* Safari 和 Chrome */
              -o-transform: rotate(270deg); /* Opera */
            }
          }
        }
      }
    }
    .order-block:last-child {
      margin-bottom: 0;
    }
  }
}
</style>